import classNames from 'classnames'
import cn from 'classnames'
import { Field as FormikField } from 'formik'
import React, { FC } from 'react'
import FieldError from '../FieldError'

import * as styles from './styles.module.scss'

interface IProps {
  name: string
  type?: string
  label?: string
  className?: string
  as?: 'input' | 'textarea'
  [key: string]: any
}

const Field: FC<IProps> = ({ name, type, label, className, as, ...props }) => {
  return (
    <div className={cn(styles.field, className)}>
      {label &&<label className={styles.label}>{label}</label>}
      <FormikField name={name} type={type} as={as} >
        {({ field, meta: { touched, error } }: any) => (
          <>
            {as === 'textarea' ? (
              <textarea className={classNames(styles.textarea, { [styles.invalid]: touched && error })} {...field} {...props} />
            ) : (
              <input className={classNames(styles.input, { [styles.invalid]: touched && error })} {...field} {...props} />
            )}
          </>
        )}
      </FormikField>
      <FieldError name={name} />
    </div>
  )
}

export default Field