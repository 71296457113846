import * as React from "react"
import { useTranslation } from "react-i18next"

import * as styles from './styles.module.scss'
import { DuitNow, GCash, GPay, Maya, Momo, PayTm, PhonePe, ThaiQrPayment, TrueMoney, UPI, VietTelPay, Zalo } from "../atoms/Icons"

const ThirdBlock = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h4 className={styles.title}>{t("readyToGo")}</h4>
        <div className={styles.texts}>
          <div className={styles.text}>
            <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 1H12" stroke="#7690F9" />
            </svg>
            {t("wideRange")}
          </div>
          <div className={styles.text}>
            <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 1H12" stroke="#7690F9" />
            </svg>
            {t("abilityToProcess")}
          </div>
          <div className={styles.text}>
            <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 1H12" stroke="#7690F9" />
            </svg>
            {t("fastIntegrationProcess")}
          </div>
        </div>
        <a href="" className={styles.button} rel="noreferrer" target="_blank">{t("apiDocuments")}</a>
      </div>
      <div className={styles.paymentLogos}>
        <UPI className={styles.logo} />
        <PayTm className={styles.logo} />
        <GPay className={styles.logo} />
        <PhonePe className={styles.logo} />
        <GCash className={styles.logo} />
        <Maya className={styles.logo} />
        <DuitNow className={styles.logo} />
        <ThaiQrPayment className={styles.logo} />
        <Zalo className={styles.logo} />
        <TrueMoney className={styles.logo} />
        <VietTelPay className={styles.logo} />
        <Momo className={styles.logo} />
      </div>
    </div>
  )
}

export default ThirdBlock