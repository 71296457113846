import * as React from "react"
import { useTranslation } from "react-i18next"
import Logo from "../atoms/Logo"

import * as styles from './styles.module.scss'
import MobileMenu from "../MobileMenu"
import { BurgerIcon } from "../atoms/Icons"
import Link from "../atoms/Link"

const Header = () => {
  const { t } = useTranslation()
  const [openMenu, setOpenMenu] = React.useState<boolean>(false)

  return (
    <header className={styles.header}>
      <Logo />
      <div className={styles.controls}>
        <Link to="#thirdBlock" className={styles.link}>{t("whatWeDo")}</Link>
        <Link to="#fourthBlock" className={styles.link}>{t("ourServices")}</Link>
        <Link to="#contactUsBlock" className={styles.link} type="button">{t("contactUs")}</Link>
      </div>
      <div className={styles.mobileControls}>
        <button className={styles.burger} onClick={() => setOpenMenu(true)}><BurgerIcon /></button>
      </div>

      <MobileMenu open={openMenu} close={() => setOpenMenu(false)} />
    </header>
  )
}

export default Header