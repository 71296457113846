import * as React from "react"
import { Trans, useTranslation } from "react-i18next"

import * as styles from './styles.module.scss'
import { AttractiveIcon, FundsIcon, PayInIcon, PayOutIcon, ReliableIcon, TransparencyIcon } from "../atoms/Icons"

const servicesList = [
  {
    title: "payIn",
    text: "acceptFunds",
    icon: <PayInIcon />
  },
  {
    title: "payOut",
    text: "sendMassPayout",
    icon: <PayOutIcon />
  },
  {
    title: "fundsSettlement",
    text: "cryptocurrencyOrBank",
    icon: <FundsIcon />
  }
]

const whyUsList = [
  {
    title: "attractiveFees",
    text: "anIndividual",
    icon: <AttractiveIcon />
  },
  {
    title: "reliableSupport",
    text: "weHaveDeveloped",
    icon: <ReliableIcon />
  },
  {
    title: "transparency",
    text: "youHaveFull",
    icon: <TransparencyIcon />
  }
]

const FourthBlock = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.wrapper}>
      <div className={styles.block}>
        <div className={styles.title}>{t("services")}</div>
        {servicesList.map((item, index) => (
          <div className={styles.item} key={index}>
            <div className={styles.itemTitle}>{item.icon}{t(item.title)}</div>
            <div className={styles.text}><Trans i18nKey={item.text} components={{ ul: <ul />, li: <li /> }} /></div>
          </div>
        ))}
      </div>
      <div className={styles.block}>
        <div className={styles.titleBlock}>
          <div className={styles.title}>{t("whyUs")}</div>
          <div className={styles.subtitle}>{t("hereAreFewReasons")}</div>
        </div>
        {whyUsList.map((item, index) => (
          <div className={styles.item} key={index}>
            <div className={styles.itemTitle}>{item.icon}{t(item.title)}</div>
            <div className={styles.text}><Trans i18nKey={item.text} components={{ ul: <ul />, li: <li /> }} /></div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default FourthBlock