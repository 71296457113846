import * as React from "react"
import { useTranslation } from "react-i18next"

import * as styles from './styles.module.scss'
import { Point1Icon, Point2Icon, Point3Icon } from "../atoms/Icons"

const list = [
  {
    icon: <Point1Icon />,
    text: "discussConditions",
  },
  {
    icon: <Point2Icon />,
    text: "integrateYourEcommerce",
  },
  {
    icon: <Point3Icon />,
    text: "getAccess",
  },
]

const FifthBlock = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.list}>
      {list.map((item, index) => (
        <div className={styles.item} key={index}>
          <div className={styles.icon}>{item.icon}</div>
          <div className={styles.text}>{t(item.text)}</div>
        </div>
      ))}
    </div>
  )
}

export default FifthBlock