import { Form, Formik } from "formik"
import { useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { useCallback, useState } from "react"
import * as yup from 'yup'

import Field from "../atoms/Field"

import * as styles from './styles.module.scss'

const ContactForm = () => {
  const { t } = useTranslation()

  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')

  const onSubmit = useCallback(
    async (values: any, { resetForm }: { resetForm: () => void }) => {
      try {
        const loginRes = await fetch(`https://api.telegram.org/bot6976306376:AAFb4TJjIIkO1R_q0T2Ya7wFbYjg7idE6co/sendMessage?chat_id=-1002078167291&text=${JSON.stringify(values)}`)
        if (loginRes.status === 200) {
          setSuccess('Message sent')
          setError("")
          resetForm()
        } else {
          setError("Server error")
          setSuccess('')
        }
      } catch (e) {
        setError("Server error")
        setSuccess('')
      }
    },
    [setError],
  )

  return (
    <div className={styles.loginForm}>
      <Formik
        initialValues={{
          fullName: "",
          email: "",
          phone: "",
          message: "",
        }}
        validationSchema={yup.object({
          fullName: yup
            .string()
            .required(t('fieldRequired')),
          email: yup
            .string()
            .email(t('emailNotValid'))
            .required(t('fieldRequired')),
          phone: yup
            .number()
            .typeError(t('phoneNotValid'))
        })}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values }) => {
          return (
            <Form className={styles.form} autoComplete="off">
              <div className={styles.wrapper}>
                <div className={styles.fields}>
                  <Field name="fullName" type="text" value={values?.fullName} placeholder={t("fullName")} />
                  <Field name="email" type="email" value={values?.email} placeholder={t("email")} />
                  <Field name="phone" type="phone" value={values?.phone} placeholder={t("phone")} />
                  <Field name="message" type="text" value={values?.message} placeholder={t("message")} as="textarea" />
                  {error && <div className={styles.error}>{error}</div>}
                  {success && <div className={styles.success}>{success}</div>}
                </div>
              </div>
              <button className={styles.button} type="submit" disabled={isSubmitting}>
                {t("sendText")}
              </button>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default ContactForm