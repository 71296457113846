import * as React from "react"
import { useTranslation } from "react-i18next"
import Logo from "../atoms/Logo"

import * as styles from './styles.module.scss'
import { FacebookIcon, InstagramIcon, LinkedInIcon, TelegramIcon, TwitterIcon } from "../atoms/Icons"

const socials = [
  // {
  //   icon: <FacebookIcon />,
  //   link: '',
  // },
  // {
  //   icon: <InstagramIcon />,
  //   link: '',
  // },
  {
    icon: <TelegramIcon />,
    link: 'https://t.me/rebornpay',
  },
  // {
  //   icon: <TwitterIcon />,
  //   link: '',
  // },
  {
    icon: <LinkedInIcon />,
    link: 'https://www.linkedin.com/company/rebornpay-com/',
  },
]

const Footer = () => {
  const { t } = useTranslation()

  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.top}>
          <Logo />
          <div className={styles.controls}>
            <a href="/TermsAndConditions.pdf" target="_blank" className={styles.link}>{t("terms")}</a>
            {/* <a href="/privacy-policy" className={styles.link}>{t("privacyPolicy")}</a> */}
            <a href="/Partnerships.pdf" target="_blank" className={styles.link}>{t("forPartners")}</a>
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.copiright}>
            © {new Date().getFullYear()} Rebornpay. All rights reserved.
          </div>
          <div className={styles.socials}>
            {socials.map(({ icon, link }, index) => (
              <a href={link} target="_blank" rel="noreferrer" key={index}>
                {icon}
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer