import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react"
import SEO from "../components/SEO";
import Header from "../components/Header";

import '../styles/globals.scss'
import * as styles from './styles.module.scss'
import Footer from "../components/Footer";
import SecondBlock from "../components/SecondBlock";
import FourthBlock from "../components/FourthBlock";
import FifthBlock from "../components/FifthBlock";
import ThirdBlock from "../components/ThirdBlock";
import ContactUsBlock from "../components/ContactUsBlock";

const IndexPage = () => {
  const { t } = useTranslation()

  return (
    <main>
      <SEO
        title={t("metaTitle")}
        description={t("description")}
      />
      <div className={styles.firstBlock}>
        <div className={styles.container}>
          <Header />
          <h1 className={styles.h1}>{t("title")}</h1>
          <h2 className={styles.subtitle}>{t("subtitle")}</h2>
        </div>
      </div>
      <div className={styles.secondBlock}>
        <div className={styles.container}>
          <SecondBlock />
        </div>
      </div>
      <div className={styles.thirdBlock} id="thirdBlock">
        <div className={styles.container}>
          <ThirdBlock />
        </div>
      </div>
      <div className={styles.fourthBlock} id="fourthBlock">
        <div className={styles.container}>
          <FourthBlock />
        </div>
      </div>
      <div className={styles.fifthBlock}>
        <div className={styles.container}>
          <FifthBlock />
        </div>
      </div>
      <div className={styles.contactUsBlock} id="contactUsBlock">
        <div className={styles.container}>
          <ContactUsBlock />
        </div>
      </div>
      <Footer />
    </main>
  )
}

export default IndexPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;