import classNames from "classnames"
import * as React from "react"
import { CloseIcon } from "../atoms/Icons"
import Logo from "../atoms/Logo"

import * as styles from './styles.module.scss'
import { useTranslation } from "react-i18next"
import Link from "../atoms/Link"

interface IProps {
  open: boolean
  close: () => void
}

const MobileMenu = ({ open, close }: IProps) => {
  const { t } = useTranslation()

  return (
    <div className={classNames(styles.mobileMenu, { [styles.open]: open })}>
      <div className={styles.header}>
        <Logo />
        <button className={styles.close} onClick={close}><CloseIcon /></button>
      </div>
      <div className={styles.content}>
        <Link
          to="#thirdBlock"
          onClick={() => {
            close()
          }}
          className={styles.link}
        >
          {t("whatWeDo")}
        </Link>
        <Link
          to="#fourthBlock"
          onClick={() => {
            close()
          }}
          className={styles.link}
        >
          {t("ourServices")}
        </Link>
        <Link
          to="#contactUsBlock"
          type="button"
          onClick={() => {
            close()
          }}
          className={styles.link}
        >
          {t("contactUs")}
        </Link>
      </div>
    </div>
  )
}

export default MobileMenu