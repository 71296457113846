import * as React from "react"
import { useTranslation } from "react-i18next"
import Logo from "../atoms/Logo"

import * as styles from './styles.module.scss'

const list = [
  {
    text: "integrations",
    count: 30
  },
  {
    text: "paymentMethods",
    count: 30
  },
  {
    text: "countries",
    count: 15
  }
]

const SecondBlock = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.list}>
      {list.map((item, index) => (
        <div className={styles.item} key={index}>
          <div className={styles.count}>{item.count}+</div>
          <div className={styles.text}>{t(item.text)}</div>
        </div>
      ))}
    </div>
  )
}

export default SecondBlock